/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { ProfileContext } from "../../navigation/ProfileContext";
import { useParams } from "react-router-dom";
// import {getSections, saveSurvey} from "../../services/api";
import {
  getSections,
  saveSurvey,
  saveGetNextQuestion,
  getQuestion,
  savePromisScores,
  getFirstQuestion,
  getFullAssessment,
  getFullAssessmentById,
  saveResult,
  getAssessmentByQrCode,
  saveScore,
  rollbackQuestions,
  setUuid,
} from "../../services/api2";
import { getPromisQuestion } from "../../services/api";
import { findByValue, objectExists, objectsAreEqual } from "../../../helpers/helpers";
import ScrollHelpers from "../../../helpers/scroll-helpers";
import Loader from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";

import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import Promis from "../../components/Promis/Promis";
import NewQuestion from "../../components/Question/NewQuestion";
import Rollback from "../../components/Rollback/Rollback";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import AdditionalInfoModal from "../../components/AdditionalInfoModal/additionalInfoModal";
import AssessmentPage from "../../components/AssessmentPage";
import Footer from "../../components/NewUI/Footer";
import Steps from "../../components/NewUI/Steps";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../components/NewUI/Loading";

import { v4 as uuidv4 } from "uuid";
import { parse, stringify, toJSON, fromJSON } from "flatted";
import SplashScreen from "../../components/NewUI/SplashScreen";
// uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
//this variables are wrong, should be in the state
let targetAfterPromis = null;
let promisType = null;
let savedQuestionIds = [];
let savedAnswerIds = [];
let painAnswerIds = [];
let painQuestionIds = [];
let oldTitle = null;
let oldDescription = null;
let bodyPart = null;

//i took the liberty of choosing this, but maybe we should store it somewhere
let numberOfQuestions = 40;

const constants = {
  TYPE_TITLE: "title",
  TYPE_DESCRIPTION: "description",
  TYPE_QUESTION: "question",
};

const urlParams = new URLSearchParams(window.location.search);
const qrCode = urlParams.get("qrcode") || null;
const userId = urlParams.get("user_id") || null;
const assessmentId = urlParams.get("assessmentid") || null;
const assessmentType = urlParams.get("type") || null;

const calculateBMI = (height, weight) => {
  let BMI = (weight / (height * height)) * 703;
  let score = BMI >= 30 ? 4 : 0;
  return score;
}

const upperBodies = [
  "Shoulder",
  "Elbow",
  "Hand/Wrist",
  "Neck",
]

const lowerBodies = [
  "Low Back",
  "Knee",
  "Hip",
  "Ankle",
  "Foot/Ankle",
]

const allBodies = [...lowerBodies, ...upperBodies];

function NewAssessment() {
  // const [selectedQuestion, setSelectedQuestion] = useState(null);
  // const [screenElements, setScreenElements] = useState([]);
  // const [elements, setElements] = useState([]);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const loadingRef = useRef(loading);
  const [finished, setFinished] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [score, setScore] = useState(0);
  const [assessment, setAssessment] = useState(null);
  const [redFlagPopupShown, setRedFlagPopupShown] = useState(false);
  // const [additionalInfo, setAdditionalInfo] = useState("");
  const [pages, setPages] = useState([]);
  // const [title, setTitle] = useState(null);
  const [pageSelected, setPageSelected] = useState(0);
  const [userCode, setUserCode] = useState("");
  const [scoreBySectionId, setScoreBySectionId] = useState({});

  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);


  let history = useHistory();

  // TODO localStorage, get uuid, if not, create it, and send it with the results

  // let { assessmentId } = useParams();

  useEffect(() => {
    // let userUUID = localStorage.getItem("userUUID");
    // if (!userUUID) {
    let userUUID = uuidv4();
    // console.log('UUID', userUUID)
    //localStorage.setItem("userUUID", userUUID);
    //}
    if (userId) {
      setUserCode(userId);
      setUuid(userId);
    } else {
      setUserCode(userUUID);
      setUuid(userId);
    }

  }, []);

  //TODO some useEffect with userUUID to see which question to start from?

  useEffect(() => {
    setLoading(true);
    // loadingRef.current = true;
    // getQuestion(93).then((res) => {
    // getQuestion(155).then((res) => {
    // getFirstQuestion(assessmentId).then((res) => {
    //     setSelectedQuestion(res);
    //     setLoading(false)
    // loadingRef.current = false;
    // })
    // getAssessmentByQrCode(qrCode).then((a) => {
    let getFullAssessmentPromise;

    if (qrCode) {
      getFullAssessmentPromise = getFullAssessment(qrCode);
    } else {
      getFullAssessmentPromise = getFullAssessmentById(assessmentId);
    }

    getFullAssessmentPromise
      .then((a) => {
        setAssessment(a);
        let savedPages;
        // savedPages = localStorage.getItem("pages");
        if (savedPages) {
          let parsedPages = parse(savedPages);
          // console.log(parsedPages);
          setPages(parsedPages);
        } else {
          let as = a.assessmentSections.find((x) => {
            return x.is_first;
          });
          if (!as) as = a.assessmentSections[0];
          let q = as.questions.find((x) => {
            return x.is_first;
          });
          if (!q) q = as.questions[0];
          q.assessmentSections = [as];
          // setSelectedQuestion(q);
          formatQuestion(q);
        }
        setLoading(false);

        // loadingRef.current = false;
      })
      .catch(() => {
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assessment) {
      const resultToSave = {};
      resultToSave.uuid = userId || userCode;
      resultToSave.question = {
        id: 0,
        target: 1,
        type: 'type',
        res: assessmentType || 'ONBOARDING',
      }
      resultToSave.target = 1;
      resultToSave.type = 'type';
      resultToSave.res = assessmentType || 'ONBOARDING';
      resultToSave.assessment_id = assessment.id;
      saveGetNextQuestion(resultToSave);
      let anotherResult = { ...resultToSave };
      anotherResult.type = 'date_completed';
      anotherResult.question.type = 'date_completed';
      let today = new Date();
      let nDate = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000);
      anotherResult.res = nDate.toISOString();
      anotherResult.question.res = nDate.toISOString();
      saveGetNextQuestion(anotherResult);
    }

  }, [assessment])

  useEffect(() => {
    if (finished) {
      setCurrentProgress(100);
    }
  }, [finished]);

  const formatQuestion = (question, currPages, currProgress, currScore, currScoreBySectionId) => {
    let text;
    let title = null;
    let description = null;
    let step = null;
    let placeholder = "";
    let validationText = "";

    if (question.promisId) {
      if (promisType === "pain") {
        title = "The next questions are called PROMIS® measures. Your answers will generate a score that helps us track your progress and adjust your program.";
        description = "The following PROMIS® questions are about your current pain level:";
        if (assessmentType.toLowerCase() === "reassessment") {
          title = "The next questions are called PROMIS® measures. Your answers will generate a score that helps us track your progress and adjust your program.";
          description = "The following PROMIS® questions are about your current pain level:";
        }
        step = 'PROMIS® Pain';
      } else if(promisType === "upperExtremity") {
        // title = "PROMIS® Questions about your current function";
        title = "The following PROMIS® questions are about your current function level:";
        // description = "The following PROMIS® questions are about your current function level:";
        step = 'PROMIS® Upper Extremity ';
        if (assessmentType.toLowerCase() === "reassessment") {
          title = "The following PROMIS® questions are about your current function level:";
          description = "";
        }
      } else {
      // title = "PROMIS® Questions about your current function";
      title = "The following PROMIS® questions are about your current function level:";
      // description = "The following PROMIS® questions are about your current function level:";
      step = 'PROMIS® Function';
      if (assessmentType.toLowerCase() === "reassessment") {
        title = "The following PROMIS® questions are about your current function level:";
        description = "";
      }
    }
      text = question.text;
    } else {
      text = question.questionTRs[0].text;
      placeholder = question.questionTRs[0].placeholder;
      validationText = question.questionTRs[0].validation_text;
      title = question.assessmentSections[0].assessmentSectionTRs[0].section_title || null;
      description = question.assessmentSections[0].assessmentSectionTRs[0].section_description || null;
      step = question.assessmentSections[0].assessmentSectionTRs[0].section_step || null;
    }
    let newPages = [...pages];
    if (currPages) {
      newPages = [...currPages];
    }
    let page;
    if (title === oldTitle || title === null) {
      page = pages.find((p) => {
        return p.title === oldTitle;
      });
      title = null;
    } else {
      //new title
      page = {
        title,
        description,
        step,
        questions: [],
      };
      if (question.promisId) {
        if (promisType === "pain") {
          //show screen promis questions coming next
        } else if (promisType === "function") {
          //show screen
        }
      }
      newPages.push(page);
      oldTitle = title;
    }

    question.page = page;

    if (description === oldDescription) {
      description = null;
    } else {
      oldDescription = description;
    }

    question.answers.sort((a, b) => {
      return a.order - b.order;
    });

    question.currentProgress = currProgress;
    question.currentScore = currScore;
    question.currentScoreBySectionId = currScoreBySectionId;

    //implement rollback

    page.questions.push({
      ...question,
      validationText,
      placeholder,
      text,
      page,
      promisType,
      answerIds: [],
    });

    const screenElement = (
      <div>
        {title && <Title text={title}></Title>}
        {description && <Title text={description}></Title>}
        <Rollback onRollback={rollback}>
          <NewQuestion
            data={{
              type: question.type,
              id: question.id,
              answers: question.answers,
              target: question.target,
              detail: question.detail,
              promisId: question.promisId,
              inputType: question.input_type,
              regex: question.regex,
              validationText: validationText,
              placeholder: placeholder,
              text: text,
            }}
            visible={true}
            onResponse={setNextQuestion}
          ></NewQuestion>
        </Rollback>
      </div>
    );

    // setScreenElements((state) => {
    //   return [...state, screenElement];
    // });

    setPages(newPages);
    localStorage.setItem("pages", stringify(newPages));
    setPageSelected(newPages.length - 1);
  };

  function incrementProgress() {
    // console.log(currentIndex)
    let incremente = 100 / numberOfQuestions;
    if (currentProgress >= 90) {
      incremente = 10 / numberOfQuestions;
    }
    let newProgress = currentProgress + incremente;
    setCurrentProgress(newProgress);
    return newProgress;
  }

  const setNextQuestion = async (result) => {
    if(allBodies.includes(result?.answer?.text)) {
      bodyPart = result.answer.text;
    }
    let pageToSetValue = pages[pages.length - 1];
    pageToSetValue.questions[pageToSetValue.questions.length - 1].value = result.res?.value;
    // result.question.value = result.res?.value;
    if (result.answer?.additional_info) {
      //   setAdditionalInfo(result.answer.additional_info);
      Swal.fire({
        title: "Alert",
        text: result.answer.additional_info,
        showCloseButton: true,
        showCancelButton: false,
        customClass: {
          confirmButton: "btn-main-limber",
          cancelButton: "btn-green-limber",
        },
        confirmButtonText: "Ok",
      });
    }


    let answerScore;
    if (result.answer?.score || result.answer?.score === 0) {
      answerScore = result.answer.score;
    } else if (result.highestScoreOption || result.highestScoreOption === 0) {
      answerScore = result.highestScoreOption;
    } else if (result.score || result.score === 0) {
      answerScore = result.score;
    }

    let newScore = score;
    let newScoreBySection = { ...scoreBySectionId };
    Object.keys(newScoreBySection).forEach((k) => {
      newScoreBySection[k] = { ...newScoreBySection[k] };
      newScoreBySection[k].results = [...newScoreBySection[k].results];
    })
    let sectionId = result?.question?.assessmentSections?.[0]?.id;
    if (sectionId && !newScoreBySection[sectionId]) {
      newScoreBySection[sectionId] = {
        results: [],
        percentage: (result.question.assessmentSections[0].score_percentage || 10) / 100
      }
    }
    if (answerScore || answerScore === 0) {
      newScore = 0;
      newScoreBySection[sectionId].results.push(answerScore);
      Object.values(newScoreBySection).forEach((section) => {
        let sectionResult = section.results.reduce((a, b) => a + b, 0);
        let plus = 0;
        //TODO lets search for a better way to do this, or to rollback the BMIscore
        if (section.BMIScore || section.BMIScore === 0) {
          sectionResult += section.BMIScore;
          plus++;
        }
        sectionResult *= section.percentage;
        if (section.results.length || plus) {
          sectionResult = sectionResult / (section.results.length + plus);
        }
        newScore += sectionResult;
      });
      setScore(newScore);
      setScoreBySectionId(newScoreBySection);
      console.log("current score is", newScore);
      saveScore(newScore, assessment.id, userCode, assessmentType);
    } else if (result.question.score_type === "BMIW") {
      newScore = 0;
      let newWeight = parseInt(result.res.value);
      if (height) {
        let BMIScore = calculateBMI(height, newWeight);
        newScoreBySection[sectionId].BMIScore = BMIScore;
      }
      setWeight(newWeight);
      Object.values(newScoreBySection).forEach((section) => {
        let sectionResult = section.results.reduce((a, b) => a + b, 0);
        let plus = 0;
        //TODO lets search for a better way to do this, or to rollback the BMIscore
        if (section.BMIScore || section.BMIScore === 0) {
          sectionResult += section.BMIScore;
          plus++;
        }
        sectionResult *= section.percentage;
        if (section.results.length || plus) {
          sectionResult = sectionResult / (section.results.length + plus);
        }
        newScore += sectionResult;
      });
      setScore(newScore);
      setScoreBySectionId(newScoreBySection);
      console.log("current score is", newScore);
      saveScore(newScore, assessment.id, userCode, assessmentType);
    } else if (result.question.score_type === "BMIH") {
      newScore = 0;
      let newHeight = parseInt(result.res.value);
      if (weight) {
        let BMIScore = calculateBMI(newHeight, weight);
        newScoreBySection[sectionId].BMIScore = BMIScore;
      }
      setHeight(newHeight);
      Object.values(newScoreBySection).forEach((section) => {
        let sectionResult = section.results.reduce((a, b) => a + b, 0);
        let plus = 0;
        //TODO lets search for a better way to do this, or to rollback the BMIscore
        if (section.BMIScore || section.BMIScore === 0) {
          sectionResult += section.BMIScore;
          plus++;
        }
        sectionResult *= section.percentage;
        if (section.results.length || plus) {
          sectionResult = sectionResult / (section.results.length + plus);
        }
        newScore += sectionResult;
      });
      setScore(newScore);
      setScoreBySectionId(newScoreBySection);
      console.log("current score is", newScore);
      saveScore(newScore, assessment.id, userCode, assessmentType);
    }


    let stopAssessment = false;
    if (result.answer?.red_flag && !redFlagPopupShown && ((assessmentType && assessmentType.toUpperCase() != 'REASSESSMENT') || !assessmentType)) {
      try {
        const result = await Swal.fire({
          title: `Based on your answers, it is recommended that you follow-up with your health care provider prior to starting this program. `,
          text: ``,
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText:
            "My health care provider advised me to proceed with this program and I would like to continue.",
          denyButtonText: "I would like to consult with my health care provider prior to starting this program.",
          customClass: {
            confirmButton: "btn-main-limber",
            denyButton: "btn-green-limber",
          },
        });
        if (result.isDenied) {
          stopAssessment = true;
        } else {
          setRedFlagPopupShown(true);
        }
        //we continue with the assessment
      } catch (e) {
        // history.push("/retakeAssessment" + history.location.search);
        //redirect to another page or something
        //we delete all the assessment answers until now?
        // return;
        stopAssessment = true;
      }
    }
    if (stopAssessment) {
      return;
    }
    //this has question and answer id of the last choice
    // console.log(result);
    // loadingRef.current = true;
    let newProgress = incrementProgress();
    // result.client_assessment_id = qrCode;
    if (result.question.detail === "PROMIS") {
      setQuestionLoading(true);
      targetAfterPromis = result.question.target;
      promisType = "pain";
      const resultToSave = {};
      resultToSave.res = (result.res && result.res.value) || result.res;
      resultToSave.question_id = result.question.id;
      resultToSave.type = result.question.type;
      resultToSave.uuid = userCode;
      resultToSave.assessment_id = assessment.id;
      resultToSave.question = {
        id: result.question.id,
        target: result.question.target,
        type: result.question.type,
        res: resultToSave.res
      };
      resultToSave.code = qrCode;
      resultToSave.score = newScore;

      // resultToSave.question = result.question;
      if (result.question.type === "multi-choice") {
        resultToSave.target = parseInt(result.answer.target)
      }
      if (result.res && result.res.value === "") {
        resultToSave.res = "";
        resultToSave.question.res = "";
      }

      if (result.res && result.res.value === null) {
        resultToSave.res = "";
        resultToSave.question.res = "";
      }
      resultToSave.assessmentType = assessmentType;
      saveGetNextQuestion(resultToSave);
      getPromisQuestion(promisType, [], []).then((response) => {
        let q = response.questions[0];
        q.detail = "ISPROMIS";
        // setSelectedQuestion(q);
        formatQuestion(q, null, newProgress, newScore, newScoreBySection);
        setQuestionLoading(false);
        // loadingRef.current = false;
      });
    } else if (result.question.detail === "ISPROMIS") {
      setQuestionLoading(true);
      savedQuestionIds.push(result.question.promisId);
      savedAnswerIds.push(result.answer.id);
      getPromisQuestion(promisType, savedQuestionIds, savedAnswerIds).then((response) => {
        if (response.score && promisType === "pain") {
          promisType = "function";
          if(upperBodies.includes(bodyPart)) {
            promisType = "upperExtremity"
          }
          savePromisScores("pain", savedQuestionIds, savedAnswerIds, response.score, userCode, assessment.id, qrCode, targetAfterPromis, assessmentType);
          let sumScore = 0;
          if (response.score > 61) {
            sumScore = 0.45
          } else if (response.score > 55) {
            sumScore = 0.30
          } else if (response.score > 40) {
            sumScore = 0.15
          }
          if(assessmentType.toLowerCase() === "reassessment") {
            if (response.score > 61) {
              sumScore = 1
            } else if (response.score > 55) {
              sumScore = 2/3
            } else if (response.score > 40) {
              sumScore = 1/3
            }
          }
          newScore += sumScore;
          setScore(newScore);
          console.log("current score is", newScore);
          saveScore(newScore, assessment.id, userCode, assessmentType);


          newScoreBySection.promisPain = {
            results: [sumScore],
            percentage: 1
            //TODO real percentage is 0.15 but iam already calculating it for sumScore
          }

          setScoreBySectionId(newScoreBySection);

          painAnswerIds = savedAnswerIds;
          painQuestionIds = savedQuestionIds;
          savedAnswerIds = [];
          savedQuestionIds = [];
          getPromisQuestion(promisType, [], []).then((response) => {
            let q = response.questions[0];
            q.detail = "ISPROMIS";
            // setSelectedQuestion(q);
            formatQuestion(q, null, newProgress, newScore, newScoreBySection);
            setQuestionLoading(false);
            // loadingRef.current = false;
          });
        } else if (response.score && (promisType === "function" || promisType === "upperExtremity")) {
          //TODO save promis type as upperExtremity if its upper extremity
          savePromisScores(promisType, savedQuestionIds, savedAnswerIds, response.score, userCode, assessment.id, qrCode, targetAfterPromis, assessmentType);
          promisType = null;
          let sumScore = 0;
          if (response.score <= 39) {
            sumScore = 0.90;
          } else if (response.score <= 45) {
            sumScore = 0.60;
          } else if (response.score < 60) {
            sumScore = 0.30;
          }
          if(assessmentType.toLowerCase() === "reassessment") {
            if (response.score <= 39) {
              sumScore = 2;
            } else if (response.score <= 45) {
              sumScore = 4/3;
            } else if (response.score < 60) {
              sumScore = 2/3;
            }
          }
          newScore += sumScore;
          setScore(newScore);
          console.log("current score is", newScore);
          saveScore(newScore, assessment.id, userCode, assessmentType);

          newScoreBySection.promisFunction = {
            results: [sumScore],
            percentage: 1
            //TODO real percentage is 0.30 but iam already calculating it for sumScore
          }

          setScoreBySectionId(newScoreBySection);

          getQuestion(targetAfterPromis)
            .then((res) => {
              // setSelectedQuestion(res);
              formatQuestion(res, null, newProgress, newScore, newScoreBySection);
              setQuestionLoading(false);
              // loadingRef.current = false;
            })
            .catch((err) => {
              Swal.fire({
                title: "Alert",
                text: `Error loading question ${targetAfterPromis}.
              Error is ${err.message}
              Please, rollback the last promis question answered and answer it again to try again, or call your medic`,
                showCloseButton: true,
                showCancelButton: false,
                customClass: {
                  confirmButton: "btn-main-limber",
                  cancelButton: "btn-green-limber",
                },
                confirmButtonText: "Ok",
              });
            });
        } else {
          let q = response.questions[0];
          q.detail = "ISPROMIS";
          // setSelectedQuestion(q);
          formatQuestion(q, null, newProgress, newScore, newScoreBySection);
          setQuestionLoading(false);
          // loadingRef.current = false;
        }
      });
    } else {
      const resultToSave = {};
      resultToSave.res = (result.res && result.res.value) || result.res;
      resultToSave.question_id = result.question.id;
      resultToSave.type = result.question.type;
      resultToSave.uuid = userCode;
      resultToSave.assessment_id = assessment.id;
      resultToSave.question = {
        id: result.question.id,
        target: result.question.target,
        type: result.question.type,
        res: resultToSave.res
      };
      resultToSave.code = qrCode;
      resultToSave.score = newScore;
      if (result.question.type === "multi-choice") {
        resultToSave.target = parseInt(result.answer.target)
      }
      if (result.res && result.res.value === "") {
        resultToSave.res = "";
        resultToSave.question.res = "";
      }
      if (result.res && result.res.value === null) {
        resultToSave.res = "";
        resultToSave.question.res = "";
      }
      resultToSave.assessmentType = assessmentType;
      // resultToSave.question.assessmentSections = null;
      // resultToSave.question.page = null;
      // assessment_id: parsedBody.assessment_id
      saveGetNextQuestion(resultToSave).then(
        (res) => {
          //nothing, partial saving is ok
        },
        (err) => {
          //toast error that save couldnt be do it, and try again later?
        }
      );

      let target = result.question.target;
      if (result.question.type === "multi-choice") {
        target = result.answer.target;
      }
      for (let i = 0; i < assessment.assessmentSections.length; i++) {
        let as = assessment.assessmentSections[i];
        let q = as.questions.find((x) => {
          return x.id == target;
        });
        if (q) {
          q.assessmentSections = [as];
          // setSelectedQuestion(q);
          formatQuestion(q, null, newProgress, newScore, newScoreBySection);
          setQuestionLoading(false);
          // loadingRef.current = false;
          return;
        }
      }
      //end
      setQuestionLoading(false);
      document.dispatchEvent(
        new MessageEvent("onFinished", {
          data: { finished: "true", userUUID: userCode },
        })
      );
      // document.dispatchEvent(new MessageEvent('onFinished', {
      //   data: result
      // }));
      // loadingRef.current = false;
      setFinished(true);
      ScrollHelpers.scrollBottom(300);
    }
  };

  const rollback = (question) => {
    if (loading) return;
    setLoading(true);
    // loadingRef.current = true;
    //but for now iam just removing them from the screen
    // checkout how to calculate the new progress
    // setCurrentProgress(newProgress);
    setFinished(false);

    oldTitle = question.page.title;
    oldDescription = question.page.description;

    let target = question.id;
    let pageIndex = pages.findIndex((p) => {
      return p === question.page;
    });
    let questionIndex = question.page.questions.findIndex((q) => {
      return q === question;
    });
    question.page.questions = question.page.questions.slice(0, questionIndex);

    // let title = null;

    // if (question.promisId) {
    //   if (promisType === "pain") {
    //     title =
    //     "PROMIS® Measures";
    //   } else {
    //     title =
    //     "PROMIS® Questions about your current function";
    //   }
    // } else {
    //   title = question.assessmentSections[0].assessmentSectionTRs[0].section_title || null;
    // }

    let newPages = pages.slice(0, pageIndex + 1);
    setCurrentProgress(question.currentProgress || 0);
    setScore(question.currentScore);
    setScoreBySectionId(question.currentScoreBySectionId);
    setPages(newPages);
    localStorage.setItem("pages", stringify(newPages));

    //iam gonna use the question id to do the rollback and save it to the db
    if (question.promisId) {
      //iam in the same promis section, no backend rollback
      if (question.promisType === promisType) {
        let index = savedQuestionIds.findIndex((x) => x === question.promisId);
        if (index !== -1) {
          savedQuestionIds = savedQuestionIds.slice(0, index);
          savedAnswerIds = savedAnswerIds.slice(0, index);
        }
        getPromisQuestion(promisType, savedQuestionIds, savedAnswerIds).then((res) => {
          let q = res.questions[0];
          q.detail = "ISPROMIS";
          // setSelectedQuestion(q);
          formatQuestion(q, newPages, question.currentProgress, question.currentScore, question.currentScoreBySectionId);
          setLoading(false);
          // loadingRef.current = false;
        });
      } else {
        if (question.promisType === "pain") {
          //if promistype is null, i should rollback score of pain and function, else only pain
          rollbackQuestions({
            type: "promis-pain",
            uuid: userCode,
            assessment_id: assessment.id
          }).then(() => {
            console.log("rollback completed");
          }).catch(() => {
            console.log("rollback failed");
          });
          promisType = "pain";
          let index = painQuestionIds.findIndex((x) => x === question.promisId);
          savedQuestionIds = painQuestionIds.slice(0, index);
          savedAnswerIds = painAnswerIds.slice(0, index);
          getPromisQuestion(promisType, savedQuestionIds, savedAnswerIds).then((res) => {
            let q = res.questions[0];
            q.detail = "ISPROMIS";
            // setSelectedQuestion(q);
            formatQuestion(q, newPages, question.currentProgress, question.currentScore, question.currentScoreBySectionId);
            setLoading(false);
            // loadingRef.current = false;
          });
        } else if(question.promisType === "upperExtremity")  {
          rollbackQuestions({
            type: "promis-upperExtremity",
            uuid: userCode,
            assessment_id: assessment.id
          }).then(() => {
            console.log("rollback completed");
          }).catch(() => {
            console.log("rollback failed");
          });
          promisType = "upperExtremity";
          let index = savedQuestionIds.findIndex((x) => x === question.promisId);
          savedQuestionIds = savedQuestionIds.slice(0, index);
          savedAnswerIds = savedAnswerIds.slice(0, index);
          getPromisQuestion(promisType, savedQuestionIds, savedAnswerIds).then((res) => {
            let q = res.questions[0];
            q.detail = "ISPROMIS";
            // setSelectedQuestion(q);
            formatQuestion(q, newPages, question.currentProgress, question.currentScore, question.currentScoreBySectionId);
            setLoading(false);
            // loadingRef.current = false;
          });
        } else {
          //i should rollback score of function
          rollbackQuestions({
            type: "promis-function",
            uuid: userCode,
            assessment_id: assessment.id
          }).then(() => {
            console.log("rollback completed");
          }).catch(() => {
            console.log("rollback failed");
          });
          promisType = "function";
          let index = savedQuestionIds.findIndex((x) => x === question.promisId);
          savedQuestionIds = savedQuestionIds.slice(0, index);
          savedAnswerIds = savedAnswerIds.slice(0, index);
          getPromisQuestion(promisType, savedQuestionIds, savedAnswerIds).then((res) => {
            let q = res.questions[0];
            q.detail = "ISPROMIS";
            // setSelectedQuestion(q);
            formatQuestion(q, newPages, question.currentProgress, question.currentScore, question.currentScoreBySectionId);
            setLoading(false);
            // loadingRef.current = false;
          });
        }
      }

      return;
    }
    rollbackQuestions({
      question_id: question.id,
      uuid: userCode,
      assessment_id: assessment.id
    }).then(() => {
      console.log("rollback completed");
    }).catch(() => {
      console.log("rollback failed");
    });

    for (let i = 0; i < assessment.assessmentSections.length; i++) {
      let as = assessment.assessmentSections[i];
      let q = as.questions.find((x) => {
        return x.id == target;
      });
      if (q) {
        q.assessmentSections = [as];
        // setSelectedQuestion(q);
        formatQuestion(q, newPages, question.currentProgress, question.currentScore, question.currentScoreBySectionId);
        setLoading(false);
        // loadingRef.current = false;
        return;
      }
    }
  };
  //Your 8-week exercise therapy program is ready for you! Your program includes 3 exercise therapy videos per week and each session is approx. 20 min.
  //{currentProgress > 0 && <ProgressBar completed={currentProgress} bgColor={'#303D50'} labelAlignment={'center'}  height={'45px'} /> }
  // console.log(screenElements.length);

  if (finished) {
    return <Container fluid className="vh-100">
      <SplashScreen splash text={assessmentType.toLowerCase() === "reassessment" ? 'Thanks for the update! ' : ''} />
    </Container>
  }

  return (
    <Container fluid className="vh-100">
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <Row className="mainContainer">
            <Col md={4} xl={3} xxl={2} xs={12}>
              <Steps
                pages={pages}
                progress={currentProgress}
                pageSelected={pageSelected}
                setPageSelected={setPageSelected}
              />
            </Col>
            <Col md={8} xl={9} xxl={10} xs={12} className="assessmentUI">
              {pages.length ? (
                <AssessmentPage
                  page={pages[pageSelected]}
                  setNextQuestion={setNextQuestion}
                  onRollback={rollback}
                  loading={questionLoading} />
              ) : (
                <></>
              )}
              <Footer pageSelected={pageSelected} numberPages={pages.length} setPageSelected={setPageSelected} />
            </Col>
          </Row>
        )
      ) : (
        <SplashScreen error />
      )}
    </Container>
  );
}

export default NewAssessment;
