import React, {useEffect, useState} from 'react';
import './Button.scss';
import AutoScroll from "../../shared/components/AutoScroll";

function Button({ title = '',onClick = () => {}}) {
    const ref = React.useRef();
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        setShouldScroll(true);
    }, [])


    return (
        <div className={'btn-component'}  ref={ref} onClick={onClick}>
            {shouldScroll && <AutoScroll
                delay={300}
                mounted={() => setShouldScroll(false)}
            /> }
            <span>{title}</span>
        </div>
    );
}
export default Button