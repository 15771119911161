import { useState, useEffect } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import Header from "../Header";
import NewProgressBar from "../NewProgressBar";
import StepDisplay from "../StepDisplay";

const stepIndexTitle = [
  'Welcome',
  'Background',
  'Injury Specific',
  'PROMIS® Pain',
  'PROMIS® Function',
  'Exercise Level',
  'Community'
]

const Steps = ({ pages, progress, pageSelected, setPageSelected }) => {
  const [index, setIndex] = useState(pageSelected);

  // console.log("el index es:" + index);
  // console.log("el page es:" + pageSelected);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setIndex(pageSelected);
  }, [pageSelected]);

  return (
    <Row className="stepsLeft">
      <Col className="ps-4 pe-4 pb-3">
        <div className="pb-2">
          <Header />
          <NewProgressBar status={progress} />
        </div>
        <div className="d-md-block d-none">
          {pages.map((p, i) => {
            return (
              <StepDisplay
                key={`step-${i}`}
                number={i + 1}
                title="Step"
                description={p.step}
                completed={pages.length - 1 > i}
                onClick={() => {
                  setPageSelected(i);
                }}
              />
            );
          })}
        </div>
        {/* <StepDisplay number={1} title="Step" description="Background information" completed />
        <StepDisplay number={2} title="Step" description="More info" />
        <StepDisplay number={3} title="Step" description="And more info" /> */}
        <div className="d-block d-md-none">
          <Carousel
            className="stepCarousel"
            activeIndex={index}
            onSelect={handleSelect}
            indicators={false}
            variant="dark"
            interval={null}
            wrap={false}
          >
            {pages.map((p, i) => {
              return (
                <Carousel.Item key={`stepCar-${i}`}>
                  <StepDisplay
                    number={i + 1}
                    title="Step"
                    description={stepIndexTitle[i]}
                    completed={pages.length - 1 > i}
                    onClick={() => {
                      setPageSelected(i);
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default Steps;
