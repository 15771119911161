import React, { useEffect, useState } from "react";
// import "./Rollback.scss";
import AutoScroll from "../../shared/components/AutoScroll";
import undoIcon from "../../../assets/undo_alter.png";
import Title from "../Title/Title";
import Question from "../Question/Question";
import NewQuestion from "../Question/NewQuestion";
import Rollback from "../Rollback/Rollback";
import TitleQuestion from "../NewUI/TitleQuestion";
import UiQuestion from "../NewUI/UiQuestion";
import NewCalendar from "../NewUI/Calendar";
import NewInput from "../NewUI/Input";
import loadingImg from "../../../assets/loading.svg"

function AssessmentPage({ page, setNextQuestion, onRollback, loading }) {
  // console.log("page is", page);
  return (
    <>
      <TitleQuestion title={page.title} />
      {page.description && <TitleQuestion title={page.description} />}
      {page.questions.length ? page.questions.map((q, i) => {
        return <UiQuestion label={q.text} question={q} onRollback={onRollback} key={`uiq-${q.id}-${i}`} >
          <NewQuestion
            question={q}
            onResponse={setNextQuestion}
          ></NewQuestion>
        </UiQuestion>
      }) : <></>}
      {
        loading && <img src={loadingImg} alt="Cargando..." />
      }

      {/* <UiQuestion label="Birthday" component={<NewCalendar />} />
      <UiQuestion label="Zip Code" component={<NewInput type="text" />} />
      <UiQuestion label="Sex" component={<NewInput type="button" text="Male" />} /> */}
    </>

    // <div>
    //   <Title text={page.title}></Title>
    //   {
    //     page.questions.length ? page.questions.map((q) => {
    // const addAnswerId = (answerId) => {
    //   q.answerIds.push(answerId)
    // }
    // return <Rollback question={q} onRollback={onRollback} >
    //   <NewQuestion
    //     data={{
    //       type: q.type,
    //       id: q.id,
    //       answers: q.answers,
    //       target: q.target,
    //       detail: q.detail,
    //       promisId: q.promisId,
    //       inputType: q.input_type,
    //       regex: q.regex,
    //       validationText: q.validationText,
    //       placeholder: q.placeholder,
    //       text: q.text,
    //     }}
    //     visible={true}
    //     onResponse={setNextQuestion}
    //     addQuestionAnswerId={addAnswerId}
    //     questionAnswerIds={q.answerIds}
    //     questionValue={q.value}
    //   ></NewQuestion>
    // </Rollback>
    //     }) : <></>
    //   }
    // </div>
  );
}
export default AssessmentPage;
