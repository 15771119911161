import { Row, Col } from "react-bootstrap";
import loading from "../../../../assets/loading.svg";

const Loading = ({ styles }) => {
  return (
    <Row className="vh-100">
      <Col md={12} className={`d-flex justify-content-center align-content-center align-items-center ${styles}`}>
        <img src={loading} alt="Cargando..." />
      </Col>
    </Row>
  );
};

export default Loading;
