import React, { useState, createContext } from 'react';

export const ProfileContext = createContext({});

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);

    return (
        <ProfileContext.Provider value={{ profile, setProfile}}>
            {children}
        </ProfileContext.Provider>
    );
};
