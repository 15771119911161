import React, { useEffect, useState } from "react";
import "./Rollback.scss";
import AutoScroll from "../../shared/components/AutoScroll";
import undoIcon from "../../../assets/undo_alter.png";

function Rollback({ question, onRollback, children }) {
  return (
    <div className={"rollback-component"}>
      <img
        src={undoIcon}
        style={{
          float: "left",
          width: 30,
          paddingTop: 8,
          marginRight: 20,
          cursor: "pointer",
          position: "absolute",
          zIndex: "1060",
        }}
        onClick={() => {
          onRollback(question);
        }}
      />
      {children}
    </div>
  );
}
export default Rollback;
