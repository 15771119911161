import React, { useEffect, useState } from "react";
import "./AssessmentDatePicker.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import ReactInputDateMask from 'react-input-date-mask';

function range(startAt = 0, to = 0) {
  let size = to - startAt;
  return [...Array(size).keys()].map((i) => i + startAt);
}
// OLD delete
// const years = range(1900, new Date().getFullYear() + 1, 1);
// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

function AssessmentDatePicker({
  question,
  onConfirmPress = () => { },
  onRollbackPress = () => { },
  confirmText = "Confirm",
  placeholder = "",
}) {
  const [value, setValue] = useState(question.value || '');
  const [confirmed, setConfirmed] = useState(value !== '');
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  useEffect(() => {
    let newValue = question.value || "";
    setValue(newValue);
    if (!newValue) {
      setConfirmed(false);
    }
  }, [question.value]);

  const handleChange = (val) => {
    setValid(false);
    // let val = event.target.value;
    setValue(val);
    let date = new Date(val);
    if(isNaN(date)) {
      setMessage('Insert a valid date');
      return;
    }

    if(date.getTime() > new Date().getTime()) {
      setMessage('Date should be less than today');
      return;
    }
    setMessage('');
    setValid(true);
  };

  const confirmPress = () => {
    if (!value) return;
    if(!valid) return;
    let date = new Date();
    // OLD DELETE
    // date = date.getFullYear();
    // if ((parseInt(value.split("-")[0]) <= parseInt(date)) && (parseInt(value.split("-")[0]) >= 0)) {
      setConfirmed(true); setMessage('');
      onConfirmPress({
        question,
        res: {
          value,
        },
      });
    // } else {
    //   setMessage('Date must be less than or equal to the current year')
    // }

  };
  return (
    <div className={"textfield"}>
      <div className={"input"}>
        {/* <input
          // autoFocus
          type={"date"}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={confirmed}
          className="datepicker-input mb-3"
        /> */}
        <ReactInputDateMask
          mask="mm/dd/yyyy"
          value={value}
          onChange={handleChange}
          alwaysShowMask={true}
          disabled={confirmed}
        />
        
        <div style={{ color: 'red', marginBottom: 10 }}>
          {message}
        </div>
      </div>
      {!confirmed && (
        <div className={"btn continue btn-blue " + (!!value ? "selectable" : "")} onClick={confirmPress}>
          <span>{confirmText ? confirmText : ""}</span>
        </div>
      )}
    </div>
  );
}
export default AssessmentDatePicker;
