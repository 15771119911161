import React from "react";
import "./styles/main.scss";
import { ProfileProvider } from "./navigation/ProfileContext";
import NewAssessment from "./screens/sections/NewAssessment";

function App() {
  return (
    <ProfileProvider>
      <NewAssessment />
    </ProfileProvider>
  );
}

export default App;
