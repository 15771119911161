import axios from "axios";
import sections from './sections.json';

const urlParams = new URLSearchParams(window.location.search);
const paramAssessmentOrg = urlParams.get('org') || 'limber';

const basePath = "https://au-dev-api.limberhealth.com/v1";

const basePathPromis = "https://limber-promis.herokuapp.com";

export const getPromisQuestion = (type = 'pain', questions, answers) => {
    return new Promise( async (resolve, reject) => {
        axios.put(`${basePathPromis}/survey/${type ? type : ''}`, {
            questions,
            answers
        })
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
        )
    });
}

export const getSections = () => {
    return new Promise( async (resolve, reject) => {
        resolve(sections);
    });
}

export const getProfile = (uid = undefined) => {
    return new Promise( async (resolve, reject) => {
        axios.get(`${basePath}/getUserByLimberId?id=${uid}`, {
            headers:{
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            }
        )
    });
}


export const saveSurvey = (data) => {
    return new Promise( async (resolve, reject) => {

        // TODO: Change this to prevent none parametrized url parameter to store surveys into hopkins
        let uri = `${basePath}/saveSurveyHopkins`;
        if(paramAssessmentOrg === 'limber'){
            uri = `${basePath}/saveSurveyLimber`
        }
        axios.post(uri, data)
            .then(res => {
                resolve(res.data);
            }).catch((err) => {
            console.log(err)
                reject(err)
            })
    });
}


