import { Container, Row, Col, Button } from "react-bootstrap";
import logo from "../../../../assets/limber-logo.png";
import athleticoLogo from "../../../../assets/athletico-logo.png";

const urlParams = new URLSearchParams(window.location.search);
const isAthletico = Boolean(urlParams.get("isAthletico")) || false;
const SplashScreen = ({ splash, error, text }) => {

  return (
    <Container fluid className="splashScreen">
      <Container>
        <Row className="vh-100 ">
          <Col md={12} className="d-flex justify-content-center align-content-center align-items-center flex-column">
            <img src={isAthletico ? athleticoLogo : logo} alt="Limber" className="logo" />

            {splash && (
              <>
                <div className="pb-5 text-center">
                  {
                    text ? <h1>
                      Thanks for the update!
                    </h1> : <>
                      {/* <h1>
                        Thanks for completing the assessment
                      </h1>
                      <h1>
                        You will receive an email once your account is verified
                      </h1> */}
                      <h1>
                        Your home-exercise therapy program is ready for you!
                      </h1>
                    </>
                  }

                </div>
              </>
            )}
            {error && (
              <div className="pb-5 text-center">
                <h1>An error ocurred, please try again in a few minutes</h1>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SplashScreen;
