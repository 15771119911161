import React, { useEffect, useState } from "react";
import "./PercPicker.scss";
import undoIcon from "../../../../assets/undo_alter.png";
function PercPicker({ question = null, onRollbackPress = () => {}, onConfirmPress, confirmText = "Confirm" }) {
  const [value, setValue] = useState(question.value || null);
  const [confirmed, setConfirmed] = useState(value !== null);

  useEffect(() => {
    let newValue = question.value || null;
    if (newValue === null) {
      setConfirmed(false);
      newValue = 0;
    }
    setValue(newValue);
  }, [question.value]);

  const confirmPress = () => {
    if (!value) return;
    let obj = {
      question,
      res: {
        value,
      },
    };
    setConfirmed(true);

    onConfirmPress(obj);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const options = [];
  for (let i = 0; i <= 100; i++) {
    options.push(i);
  }

  return (
    <div className={""}>
      <div className={"select mb-3"}>
        <select value={value} onChange={handleChange} disabled={confirmed}>
          {options.map((option) => {
            return <option value={option}>{`${option}%`}</option>;
          })}
        </select>
      </div>

      {!confirmed && (
        <div className={"btn continue btn-blue " + (!!value ? "selectable" : "")} onClick={confirmPress}>
          <span>{confirmText ? confirmText : ""}</span>
        </div>
      )}
    </div>
  );
}
export default PercPicker;
