import { Row, Col } from "react-bootstrap";

const TitleQuestion = ({ title }) => {
  return (
    <Row>
      <Col md={12} xl={8} xxl={6}>
        <div className="titQuestion w-100">
          <h1>{title}</h1>
        </div>
      </Col>
    </Row>
  );
};

export default TitleQuestion;
