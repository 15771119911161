import { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { HiArrowLeft } from "react-icons/hi";
import AutoScroll from "../../../shared/components/AutoScroll";

const Footer = ({ pageSelected, numberPages, setPageSelected }) => {
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    setShouldScroll(true);
  }, []);

  return (
    <Row>
      <Col
        xxl={12}
        xl={12}
        md={12}
        className="d-flex justify-content-md-between pt-4 border-top  mt-4 flex-md-row flex-column"
      >
        {pageSelected > 0 && numberPages > 1 ? (
          <Button className="btn btn-transparent mb-3" onClick={() => setPageSelected((state) => state - 1)}>
            <HiArrowLeft /> Back to previous page
          </Button>
        ) : (
          <></>
        )}
        {pageSelected < numberPages - 1 ? (
          <>
            {shouldScroll && <AutoScroll delay={300} mounted={() => setShouldScroll(false)} />}

            <Button className="btn btn-blue pt-1" onClick={() => setPageSelected((state) => state + 1)}>
              Continue to next page
            </Button>
          </>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default Footer;

// {(pageSelected > 0 && pages.length > 1) ? <button onClick={() => setPageSelected(state => state - 1)}>before</button> : <></>}
// {pageSelected < (pages.length - 1) ? <button onClick={() => setPageSelected(state => state + 1)}>next</button> : <></>}
