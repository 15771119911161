import { Row, Col } from "react-bootstrap";
import logo from "../../../../assets/limber-logo.png";
import athleticoLogo from "../../../../assets/athletico-logo.png";

const urlParams = new URLSearchParams(window.location.search);
const isAthletico = Boolean(urlParams.get("isAthletico")) || false;

const Header = () => {
  return (
    <Row>
      <Col md={12} className="pt-4 pb-4">
        <img src={isAthletico ? athleticoLogo : logo} alt={"Limber"} className="logo" />
      </Col>
    </Row>
  );
};

export default Header;
