import React, { useEffect, useState } from "react";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

import undoIcon from "../../../../assets/undo_alter.png";
function FlatPicker({
  question = null,
  options = [],
  answerIds = [],
  onOptionPress = () => {},
  onRollbackPress = () => {},
  onConfirmPress,
  confirmText,
}) {
  const isMulti = !!onConfirmPress;

  const [confirmed, setConfirmed] = useState(!!answerIds.length);

  const confirmPress = () => {
    let obj = {
      question,
      res: {
        value: answerIds.join(),
      },
    };
    setConfirmed(true);

    onConfirmPress(obj);
    if (!obj.res.value) {
      onOptionPress({
        question: question,
        answer: "none",
        res: {
          value: "none",
          target: "none",
        },
      });
    }
  };

  useEffect(() => {
    if (question) {
      if (answerIds.length === 0) {
        setConfirmed(false);
      }
      // setConfirmed(!!answerIds.length)
    }
  }, [question, answerIds]);

  return (
    <div className={`flatPicker d-flex justify-content-start flex-wrap`}>
      {options.map((option) => {
        if (option) {
          const isSelected = answerIds.indexOf(option.promisId ? option.promisId : option.id) > -1;

          let classes = "btn btn-options  me-3 mb-3 ";

          if (isSelected) {
            classes += "active";
          } else {
            //same if
            if (isMulti) {
              if (!confirmed) {
                classes += "selectable";
              } else {
                classes += "disabled";
              }
            } else if (answerIds.length === 0) {
              classes += "selectable";
            } else {
              classes += "disabled";
            }
          }

          return (
            <div key={`promis_${option.promisId ? option.promisId : option.id}`}>
              <div
                key={option.promisId ? option.promisId : option.id}
                className={classes}
                onClick={() => {
                  //same if
                  if ((isMulti && !confirmed) || (!isMulti && answerIds.length === 0)) {
                    onOptionPress({
                      question: question,
                      answer: option,
                      res: {
                        value: option.id,
                        target: option.target,
                      },
                    });
                  }
                }}
              >
                <span>
                  {isSelected ? <BsCheckCircle className="me-1" /> : <BsCircle className="me-1" />}

                  {option.text}
                </span>
              </div>
            </div>
          );
        }
        return null;
      })}
      {isMulti && !confirmed ? (
        <div className={"btn btn-blue selectable"} onClick={confirmPress}>
          <span> {confirmText ? confirmText : ""}</span>
        </div>
      ) : null}
    </div>
  );
}
export default FlatPicker;
