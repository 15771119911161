import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function AdditionalInfoModal({ title = "", message = "" }) {
  return Swal.fire({
    title: title,
    text: message,
    showCloseButton: true,
    showCancelButton: false,
    customClass: {
      confirmButton: "btn-main-limber",
      cancelButton: "btn-green-limber",
    },
    confirmButtonText: "Back",
  });
}
export default AdditionalInfoModal;
