import { Row, Col } from "react-bootstrap";
import { FaUndo } from "react-icons/fa";

const UiQuestion = ({ label, onRollback, question, children }) => {
  return (
    <Row>
      <Col md={12} className="questioUI">
        <div className="pb-2">
          <label>{label}</label>
        </div>

        <div className="d-flex justify-content-start w-100">
          <div className="pe-3 pt-1">
            <FaUndo
              size={20}
              className="undoButton"
              onClick={() => {
                onRollback(question);
              }}
            />
          </div>
          <div className="w-100">
            {children}
            {/* here comes the input */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UiQuestion;
