import { Row, Col, ProgressBar } from "react-bootstrap";

const NewProgressBar = ({ status }) => {
  return (
    <Row>
      <Col className="progressBar">
        <h5>{status}% Complete</h5>
        <ProgressBar now={status} />
      </Col>
    </Row>
  );
};

export default NewProgressBar;
