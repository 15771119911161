import { Col, Row } from "react-bootstrap";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

const StepDisplay = ({ number, title, description, completed, onClick }) => {
  return (
    <Row onClick={onClick} style={{ cursor: "pointer" }}>
      <Col className="d-flex justify-content-md-between justify-content-center  align-content-center align-items-center stepDisplay pt-4 flex-column flex-md-row">
        <div className="order-1 order-md-0 text-md-start text-center">
          <h5>
            {title} {number}: {description}
          </h5>
        </div>
        <div className="order-0 order-md-1 mb-md-0 mb-3">
          {completed ? <BsCheckCircle size={25} /> : <BsCircle size={25} />}
        </div>
      </Col>
    </Row>
  );
};

export default StepDisplay;
